<template>
  <div class="bofangChater">
    <div class="box-item"><i class="iconfont icon-a-zu335"></i>课程列表</div>
    <div class="chat-item" v-for="(item, index) in CourseList" :key="index">
      <div
        class="item-title"
        :class="playid == item.id ? 'active' : ''"
        @click.stop="check(item, 1)"
      >
        <div class="dis_flex">
          <i class="iconfont icon-a-Union21"></i>
          <div class="title w-b-100 ellipsis">
            {{ item.title }}
          </div>
        </div>
        <div
          class="video iconfont icon-a-Union21"
          @click.stop="goLive(item, item.isLive)"
        ></div>
      </div>
      <div class="but-list">
        <div
          class="keep-learn"
          v-if="item.isHasContantJY == 'true'"
          @click="ktData(item, 4)"
        >
          随堂讲义
        </div>
        <div
          class="keep-learn"
          v-if="item.isHasContant == 'true'"
          @click="cgeckTask(item)"
        >
          随堂测试
        </div>
        <div
          class="keep-learn"
          v-if="item.isHasDoc == 'true'"
          @click="ktData(item, 2)"
        >
          随堂资料
        </div>
        <div class="keep-learn" @click.stop="check(item, 1)">观看课程</div>
        <div
          class="keep-learn"
          @click="cgeckTask(item)"
          v-if="item.isHasContant == 'true'"
        >
          课后作业
        </div>
        <div
          class="keep-learn"
          @click="ktData(item)"
          v-if="item.isHasDoc == 'true'"
        >
          随堂资料
        </div>
      </div>
    </div>

    <div
      class="warpCpons"
      @touchmove.stop.prevent="stop()"
      v-if="dialogVisible"
    >
      <div @click.stop="tChuang">
        <img class="imga" src="@/assets/img/kecheng/No1.png" />
        <img
          class="imgb"
          src="@/assets/img/kecheng/close.png"
          @click.stop="tChuang"
        />
        <span>您暂未购买该课程哦~</span>
      </div>
    </div>
    <!-- 是否可以观看弹窗 -->
    <div class="lookBg" v-if="showLookDetail">
      <div class="ifLook">
        <div class="prompt">提示</div>
        <div class="contents">
          您当前正在<span>{{ lookDeatil ? lookDeatil.terminal : "" }}</span
          >观看<span>{{ lookDeatil ? lookDeatil.chapterName : "" }}</span
          >,请关闭后再行观看其他章节。
        </div>
        <div class="button" @click="closeLook">关闭</div>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import { getLbClassCourseById, neteasyRegist, userJoinTeam } from "@/api/home";
import { IsLive } from "@/api/emun";
import { getInfo, getToken } from "@/api/cookies";
let NIM = require("@/assets/js/NIM_Web_SDK_v8.9.0.js");
import emojiList from "@/assets/json/emoji.json";
import emoji from "@/components/emoji.vue";
import { AnswerClient } from "@/api/task/answerClient";
import {
  courseCheckPlay,
} from "@/api/know";
let answerClient = new AnswerClient();
export default {
  components: {
    emoji,
  },
  data() {
    return {
      userInfo: null,
      IsLive: IsLive,
      CourseList: [],
      dialogVisible: false,
      barrage: "", // 输入框
      nim: null,
      massageList: {}, // 消息列表
      massage: [],
      emojiList: [], // 表情列表
      uAccid: null,
      uToken: null,
      wxQunId: null, // 网易云信群ID
      inputInfo: false,
      lookDeatil: null,
      showLookDetail: false,
    };
  },
  props: [
    "id",
    "playid",
    "list",
    "IsPurchase",
    "classId",
    "detail",
    "classProductLine",
  ],
  async created() {
    this.wxQunId = await this.detail.wxQunId;
    this.userInfo = await getInfo();
    await this.neteasyRegist();
    if (this.list) {
      this.CourseList = this.list;
      for (let item of this.CourseList) {
        if (!item.list) {
          item.list = item.videoList;
        }
      }
    } else {
      getLbClassCourseById(this.id).then((res) => {
        this.CourseList = res.data.details.liveshowplaybackList;
      });
    }
    this.emojiList = emojiList;
  },

  beforeDestroy() {
    this.nim.destroy({
      done: function (err) {
      },
    });
  },
  methods: {
    /* 注册网易云信 */
    neteasyRegist() {
      let params = {
        mobile: this.userInfo.account,
        userName: this.userInfo.nickName,
        uId: this.userInfo.wxStuId,
        icon: this.userInfo.img,
      };
      neteasyRegist(params).then((res) => {
        if (res.code == 0) {
          this.uAccid = res.data.uAccid;
          this.uToken = res.data.uToken;
        }
        if (this.wxQunId) {
          this.init();
        }
      });
    },
    /* 群组入群 */
    userJoinTeam() {
      let params = {
        tid: this.wxQunId,
        accid: this.uAccid,
      };
      userJoinTeam(params).then((res) => {});
    },
    //初始化
    async init() {
      var _this = this;
      this.nim = await NIM.NIM.getInstance({
        debug: false, // 是否开启日志，将其打印到console。集成开发阶段建议打开。
        appKey: "7b8d4cb7e00e4065cd430b56843b3762",
        account: this.uAccid,
        /* account: '1468871691676213335', */
        token: this.uToken,
        /* token: 'a6b516f1725d07a3cf259157591646e0', */
        db: true, //若不要开启数据库请设置false。SDK默认为true。
        onconnect: onConnect, // 连接建立后的回调
        onwillreconnect: onWillReconnect, // 即将重连的回调
        ondisconnect: onDisconnect, //断开连接后的回调
        onerror: onError, //发生错误的回调, 会传入错误对象
        onmsg: onMsg,
      });
      function onConnect(obj) {
        _this.joinTheGroup();
      }
      function onWillReconnect(obj) {
        // 此时说明 SDK 已经断开连接, 请开发者在界面上提示用户连接已断开, 而且正在重新建立连接
      }
      function onDisconnect(error) {
        // 此时说明 SDK 处于断开状态, 开发者此时应该根据错误码提示相应的错误信息, 并且跳转到登录页面
      }
      function onError(error) {
      }
      function onMsg(msg) {
        _this.pushMsg(msg);
      }
    },
    /* 申请入群 */
    joinTheGroup() {
      let _this = this;
      this.nim.applyTeam({
        teamId: this.wxQunId,
        ps: "请加",
        done: function applyTeamDone(error, obj) {
          _this.inputInfo = true;
          _this.getGroupMessList();
        },
      });
    },
    /* 获取历史消息 */
    getGroupMessList() {
      let _this = this;
      this.nim.getLocalMsgs({
        sessionId: `team-${this.wxQunId}`, //表示单聊场景(p2p)，对方账号为account。
        limit: 10000,
        desc: false,
        done: getLocalMsgsDone,
      });
      function getLocalMsgsDone(error, obj) {
        for (let msg of obj.msgs) {
          if (msg.type == "text") {
            msg.text = msg.text.replace(
              /\【myEmoji：.+?\】/g,
              function (item, index) {
                let index1 = item.indexOf("】");
                let data = Number(item.substring(9, index1));
                return _this.emojiList[data];
              }
            );
          }
        }
        if (!error) {
          _this.massageList.msgs = obj.msgs;
          _this.$forceUpdate();
          _this.scrollbottom();
        }
      }
    },
    /* 发送消息 */
    sendMessage(event) {
      event.preventDefault(); // 阻止浏览器默认换行操作
      this.canClick = false;
      let that = this;
      this.massage = this.nim.sendText({
        scene: "team", //消息场景
        to: this.wxQunId,
        text: that.barrage /* .replace(/^\s+|\s+$/g, "") */,
        done: sendMsgDone,
      });
      that.pushMsg(this.massage);
      function sendMsgDone(error, msg) {
        that.pushMsg(msg);
      }
      this.canClick = true;
      this.barrage = "";
    },
    //聊天记录拼接
    async pushMsg(msgs) {
      let _this = this;
      if (!Array.isArray(msgs)) {
        msgs = [msgs];
      }
      for (let msg of msgs) {
        if (msg.type == "text") {
          msg.text = msg.text.replace(
            /\【myEmoji：.+?\】/g,
            function (item, index) {
              let index1 = item.indexOf("】");
              let data = Number(item.substring(9, index1));
              return _this.emojiList[data];
            }
          );
        }
      }
      this.massageList.msgs = this.nim.mergeMsgs(this.massageList.msgs, msgs);

      this.$forceUpdate();
      this.scrollbottom();
    },
    //滚动到底
    scrollbottom() {
      this.$nextTick(() => {
        let msgBlock = document.getElementById("msgBlock");
        msgBlock.scrollTop = msgBlock.scrollHeight;
      });
    },
    /* 点击表情 */
    isOk(val) {
      this.barrage += val;
    },
    tChuang() {
      this.dialogVisible = false;
    },
    stop() {
      return;
    },
    //观看回放
    check(item, groupId) {
      let userInfo = getInfo();
      if (!userInfo) {
        Vue.prototype.goLoginView(false);
        return;
      }
      if (this.playid == item.id) {
        return;
      }
      let sub = {
        id: item.id,
        classId: this.classId,
        classProductLine: this.classProductLine,
        chapterName: item.title,
      };
      let data = Vue.prototype.export(sub);
      if (item.isLive == 0) {
        this.$message.error("课程还未到开播时间");
        return;
      }
      if (item.isLive == 2) {
        this.$message.error("课程直播结束");
        return;
      }
      courseCheckPlay({
        courseType: "1",
        userId: this.userInfo.id,
        chapterId: item.id,
        source: 1,
      }).then((res) => {
        if (res.code == 0) {
          this.$router.push(`/livebofang?${data}`);
        } else {
          this.lookDeatil = res.msg;
          this.showLookDetail = true;
        }
      });
    },
    // 课堂作业
    cgeckTask(item) {
      answerClient.selectPaperSubjectList(item.sId).then((res) => {
        if (res.code == 0) {
          Vue.prototype.goExam(
            item.title,
            item.sId,
            2,
            2,
            this.IsPurchase,
            res.data.paper.seeNum,
            this.id,
            this.playid,
            this.classId
          );
        }
      });
    },
    closeLook() {
      this.showLookDetail = false;
    },
    //随堂资料
    ktData(item, type) {
      let userInfo = getInfo();
      if (!userInfo) {
        Vue.prototype.goLoginView(false);
        return;
      }
      if (!this.IsPurchase) {
        this.dialogVisible = true;
        return;
      }
      window.open(
        `/data/dataPackageDetail?courId=${this.id}&type=${type}&ChatId=${item.id}`,
        "_blank"
      );

    },
  },
};
</script>
<style lang="less" scoped>
.bofangChater {
  background: #383838;
  border-radius: 8px 8px 8px 8px;
  height: 100%;
  overflow: hidden;
  scrollbar-width: none;
  overflow-y: scroll;
  width: 100%;
  .box-item {
    padding: 0px 20px;
    align-items: center;
    display: flex;
    height: 48px;
    line-height: 48px;
    background: #444444;
    border-radius: 8px 8px 0px 0px;
    font-size: 16px;
    font-family: Microsoft YaHei-Regular;
    font-weight: 400;
    color: #ffffff;
    .iconfont {
      font-size: 16px;
      margin-right: 3px;
    }
    margin-bottom: 4px;
  }
}
//隐藏滚动条
::-webkit-scrollbar {
  display: none !important;
  scrollbar-width: none !important;
  -ms-overflow-style: none;
}
/deep/ .el-collapse {
  border: none !important;
  .el-collapse-item__header {
    padding: 0px 20px;
    height: 48px;
    background: #555555;
    border-radius: 0px 0px 0px 0px;
    opacity: 1;
    line-height: 48px;
    font-size: 16px;
    font-family: Microsoft YaHei-Regular;
    font-weight: 400;
    color: #ffffff;
    border: none;
    margin-top: 4px;
  }
  .el-collapse-item__content {
    padding: 0px;
  }
  .el-collapse-item__wrap {
    border: none;
  }
}
/* 试听 */
.audition {
  width: 48px;
  height: 20px;
  background: #ffedeb;
  border-radius: 2px 2px 2px 2px;
  font-size: 12px;
  font-family: Microsoft YaHei-Regular;
  font-weight: 400;
  color: #ff5e51;
  line-height: 20px;
  margin-left: 8px;
}
/* 节 */
.chat-item {
  width: 100%;
  border-bottom: 1px solid #555555;
  padding: 16px 20px;
  background: #383838;
  .active {
    color: #ff5e51;
    .iconfont,
    .title {
      color: #ff5e51 !important;
    }
  }
  .item-title {
    display: flex;
    width: 100%;
    align-items: center;
    cursor: pointer;
    width: calc(320px - 32px);
    justify-content: space-between;
    .iconfont {
      font-size: 14px;
      margin-right: 12px;
      color: #ffffff;
      flex-shrink: 0;
    }

    .title {
      max-width: calc(320px - 32px - 26px - 56px);
      font-size: 14px;
      font-family: Microsoft YaHei-Regular;
      font-weight: 400;
      color: #ffffff;
      line-height: 14px;
    }
  }

  .but-list {
    flex-shrink: 0;
    display: flex;
    justify-content: flex-end;
    margin-top: 24px;
  }
}
.keep-learn {
  margin-left: 12px;
  height: 24px;
  padding: 0px 12px;
  border-radius: 4px 4px 4px 4px;
  opacity: 1;
  border: 1px solid #ffffff;
  font-size: 12px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  line-height: 22px;
}
.bofangChater /deep/ .el-input__inner {
  background-color: #4b4b4b;
  border: 1px solid #4b4b4b;
  color: #ffffff;
  width: 220px;
}
.barrageInput {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 98%;
  margin-left: 1%;
  position: relative;
  .mt-10 {
    cursor: pointer;
    margin-right: 60px;
    position: absolute;
    right: 5px;
  }
  .sendBtn {
    position: absolute;
    right: 0;
  }
}
.barrageInput /deep/ .el-input-group__append {
  background-color: #4b4b4b;
  border: 1px solid #4b4b4b;
}
.white {
  font-size: 13px;
  padding: 5px;
  .wrap {
    .formNick {
      line-height: 21px;
    }
    .text {
      border-radius: 8px;
      background-color: #4b4b4b;
      padding: 12px;
      max-width: 90%;
      display: inline-block;
      word-wrap: break-word;
    }
  }
}
.information {
  height: 275px;
  overflow: auto;
  display: flex;
  flex-direction: column;
}
.dis-start {
  display: flex;
  flex-direction: column;
  align-items: start;
}
.dis-end {
  display: flex;
  flex-direction: column;
  align-items: end;
}
.lookBg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
  .ifLook {
    width: 500px;
    background-color: #fff;
    padding: 10px;
    border-radius: 10px;
    border: 1px solid #ccc;
    .contents {
      width: 380px;
      margin: 20px auto;
      & > span {
        color: red;
      }
    }
    .button {
      width: 120px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 auto;
      border-radius: 10px;
      border: 1px solid #ccc;
      cursor: pointer;
    }
  }
}
</style>
