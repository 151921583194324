<template>
  <div class="bofangChater">
    <!-- <div class="box-item"><i class="iconfont icon-a-zu335"></i>课程列表</div>
    <div class="chat-item" v-for="(item, index) in CourseList" :key="index">
      <div class="item-title" :class="playid == item.id ? 'active' : ''" @click.stop="check(item, 1)">
        <div class="dis_flex">
          <i class="iconfont icon-a-Union21"></i>
          <div class="title w-b-100 ellipsis">
            {{ item.title }}
          </div>
        </div>
        <div v-if="playid == item.id" class="title ellipsis shrink0">正在播放</div>
        <div v-else></div>
        <div class="video iconfont icon-a-Union21" @click.stop="goLive(item, item.isLive)"></div>
      </div>
      <div class="but-list">
        <div class="keep-learn" v-if="item.isHasContantJY == 'true'" @click="ktData(item, 4)">随堂讲义</div>
        <div class="keep-learn" v-if="item.isHasContant == 'true'" @click="cgeckTask(item)">随堂测试</div>
        <div class="keep-learn" v-if="item.isHasDoc == 'true'" @click="ktData(item, 2)">随堂资料</div>
         <div class="keep-learn" @click.stop="check(item, 1)">观看课程</div>
            <div class="keep-learn" @click="cgeckTask(item)" v-if="item.isHasContant == 'true'">课后作业</div>
            <div class="keep-learn" @click="ktData(item)" v-if="item.isHasDoc == 'true'">随堂资料</div>
      </div>
    </div> -->
    <div class="box-item"><i class="iconfont icon-a-zu335" />讨论区</div>
    <div id="msgBlock" class="information">
      <div v-for="(item, index) in list" :key="index" class="white">
        <div class="wrap" :class="item.bizFromId == userInfo.wxStuId ? 'dis-end' : 'dis-start'">
          <div class="formNick">{{ item.bizFromName }}</div>
          <div class="text">{{ item.text }}</div>
        </div>
        <div style="clear: both" />
      </div>
    </div>

    <div v-if="imQunId" class="barrageInput">
      <el-input v-model="barrage" class="input" placeholder="请输入" onkeydown="if (event.keyCode===13){return false;}"
        @keyup.enter.native="sendMessage" />
      <!-- <emoji class="mt-10" @isOk="isOk" :ifIndex="true" /> -->
      <el-button size="mini" type="primary" class="sendBtn" @click="sendMessage">发送</el-button>
    </div>
    <div v-else class="barrageInput">
      <el-input v-model="barrage" placeholder="暂时无法发言" disabled />
    </div>
    <div v-if="dialogVisible" class="warpCpons" @touchmove.stop.prevent="stop()">
      <div @click.stop="tChuang">
        <img class="imga" src="@/assets/img/kecheng/No1.png">
        <img class="imgb" src="@/assets/img/kecheng/close.png" @click.stop="tChuang">
        <span>您暂未购买该课程哦~</span>
      </div>
    </div>
    <!-- 是否可以观看弹窗 -->
    <div v-if="showLookDetail" class="lookBg">
      <div class="ifLook">
        <div class="prompt">提示</div>
        <div class="contents">
          您当前正在<span>{{ lookDeatil ? lookDeatil.terminal : '' }}</span>观看<span>{{ lookDeatil ? lookDeatil.chapterName :
            ''
            }}</span>,请关闭后再行观看其他章节。
        </div>
        <div class="button" @click="closeLook">关闭</div>
      </div>
    </div>
  </div>
</template>
<script>
import Bus from '@/api/bus'
import Vue from 'vue'
import {
  joinGroups,
  getGroupChatLog,
  queryGroupMsg,
  sendMessage,
  getLbClassCourseById,
  neteasyRegist,
  userJoinTeam
} from '@/api/home'
import { IsLive } from '@/api/emun'
import { getInfo, getToken } from '@/api/cookies'
const NIM = require('@/assets/js/NIM_Web_SDK_v8.9.0.js')
import emojiList from '@/assets/json/emoji.json'
import emoji from '@/components/emoji.vue'
import { AnswerClient } from '@/api/task/answerClient'
import { courseCheckPlay } from '@/api/know'
const answerClient = new AnswerClient()
export default {
  components: {
    emoji
  },
  props: [
    'id',
    'playid',
    'IsPurchase',
    'classId',
    'detail',
    'classProductLine'
  ],
  data() {
    return {
      userInfo: {},
      IsLive: IsLive,
      CourseList: [],
      dialogVisible: false,
      barrage: '', // 输入框
      nim: null,
      massageList: {}, // 消息列表
      massage: [],
      emojiList: [], // 表情列表
      uAccid: null,
      uToken: null,
      wxQunId: null, // 网易云信群ID
      inputInfo: false,
      list: [],
      token: '',
      req: '',
      items: {},
      im_websocket: '',
      ws_server_url: '',
      im_time_interval: '',
      count: 0,
      lookDeatil: null,
      showLookDetail: false,
      wssresult: ''
    }
  },
  async created() {
    this.imQunId = await this.detail.imQunId
    this.$forceUpdate()
    const obj = {
      accessKeyId: 'JXYqi1xr89ekrnly17sknari',
      accessKeySecret: 'w4fqam7qpjv5f8406e369njcvfmiga'
    }
    const jsonStr = JSON.stringify(obj)

    this.wssresult = btoa(jsonStr)
    this.userInfo = await getInfo()
    this.ws_server_url = `wss://gateway.juxue.cloud/imserver/ws/${this.userInfo.wxStuId}/${this.wssresult}`
    // await this.neteasyRegist()
    // if (this.list) {
    // this.CourseList = this.list
    // for (let item of this.CourseList) {
    //   if (!item.list) {
    //     item.list = item.videoList
    //   }
    // }
    // } else {
    //   getLbClassCourseById(this.id).then((res) => {
    //     this.CourseList = res.data.details.liveshowplaybackList
    //   })
    // }
    // this.emojiList = emojiList
    this.im_connect()

    this.joinGroup()
  },

  beforeDestroy() {
    this.nim.destroy({
      done: function (err) { }
    })
  },
  methods: {
    stop() {
      return
    },
    // 观看回放
    check(item, groupId) {
      const userInfo = getInfo()
      if (!userInfo) {
        Vue.prototype.goLoginView(false)
        return
      }
      if (this.playid == item.id) {
        return
      }
      const sub = {
        id: item.id,
        classId: this.classId,
        classProductLine: this.classProductLine,
        chapterName: item.title
      }
      const data = Vue.prototype.export(sub)
      if (item.isLive == 0) {
        this.$message.error('课程还未到开播时间')
        return
      }
      if (item.isLive == 2) {
        this.$message.error('课程直播结束')
        return
      }
      courseCheckPlay({
        courseType: '1',
        userId: this.userInfo.id,
        chapterId: item.id,
        source: 1
      }).then((res) => {
        if (res.code == 0) {
          this.$router.push(`/livebofang?${data}`)
        } else {
          this.lookDeatil = res.msg
          this.showLookDetail = true
        }
      })
    },
    closeLook() {
      this.showLookDetail = false
    },
    // 课堂作业
    cgeckTask(item) {
      answerClient.selectPaperSubjectList(item.sId).then((res) => {
        if (res.code == 0) {
          Vue.prototype.goExam(
            item.title,
            item.sId,
            2,
            2,
            this.IsPurchase,
            res.data.paper.seeNum,
            this.id,
            this.playid,
            this.classId
          )
        }
      })
    },
    // 随堂资料
    ktData(item, type) {
      const userInfo = getInfo()
      if (!userInfo) {
        Vue.prototype.goLoginView(false)
        return
      }
      if (!this.IsPurchase) {
        this.dialogVisible = true
        return
      }
      window.open(
        `/data/dataPackageDetail?courId=${this.id}&type=${type}&ChatId=${item.id}`,
        '_blank'
      )

      // this.$router.push(`/data/dataPackageDetail?courId=${this.id}&type=${type}&ChatId=${item.id}`);
    },
    async joinGroup() {
      await joinGroups({
        bizGroupId: this.imQunId,
        bizUserId: this.userInfo.wxStuId,
        bizUserName: this.userInfo.nickname
      }).then(async (res1) => {
        if (res1.code == 0) {
          await getGroupChatLog({ bizGroupId: this.imQunId }).then((res2) => {
            if (res2.code == 0) {
              this.list = res2.data
              this.scrollbottom()
            }
          })
        } else {
        }
      })
    },

    // 发送信息
    async sendMessage(event) {
      event.preventDefault() // 阻止浏览器默认换行操作
      await sendMessage({
        type: 1,
        bizFrom: this.userInfo.wxStuId,
        bizTo: this.imQunId,
        text: this.barrage
      })
      this.barrage = ''
    },

    im_connect() {

      try {
        this.count = this.count + 1
        this.im_ = new WebSocket(this.ws_server_url)
      } catch (evt) {

        this.im_websocket = null
      }
      this.im_websocket.onopen = this.im_onOpen
      this.im_websocket.onclose = this.im_onClose
      this.im_websocket.onmessage = this.im_onMessage
      this.im_websocket.onerror = this.im_onError
    },
    im_onOpen(evt) {

      this.im_websocket.send(
        JSON.stringify({
          // 连接成功将，类型回传给服务端
          requestId: this.req,
          reqMsg: this.userInfo.nickName,
          type: 1
        })
      )
      this.im_time_interval = window.setInterval(this.im_send, 10000) // 开始每10s发送一次ping消息

    },
    im_onClose(evt) {

    },
    im_onMessage(evt) {
      const a = JSON.parse(evt.data)

      if (a.type == 1) {
        if (a.bizToId == this.imQunId) {
          this.list.push(a)
          this.$forceUpdate()
          this.scrollbottom()
        }
      }
    },
    im_onError(evt) {

    },
    im_send() {
      if (this.im_websocket.readyState == WebSocket.OPEN) {
        this.im_websocket.send(
          JSON.stringify({

            requestId: this.req,
            reqMsg: 'ping msg',
            type: 3
          })
        )
      } else {
        clearInterval(this.im_time_interval)
        if (this.count < 3) {
          // 自动连接3次
          this.im_connect()
        } else {

        }
      }
    },
    // 滚动到底
    scrollbottom() {
      this.$nextTick(() => {
        const msgBlock = document.getElementById('msgBlock')
        msgBlock.scrollTop = msgBlock.scrollHeight
      })
    }
  }
}
</script>
<style lang="less" scoped>
.bofangChater {
  background: #383838;
  border-radius: 8px 8px 8px 8px;
  height: 100%;
  overflow: hidden;
  scrollbar-width: none;
  overflow-y: scroll;
  width: 100%;

  .box-item {
    padding: 0px 20px;
    align-items: center;
    display: flex;
    height: 48px;
    line-height: 48px;
    background: #444444;
    border-radius: 8px 8px 0px 0px;
    font-size: 16px;
    font-family: Microsoft YaHei-Regular;
    font-weight: 400;
    color: #ffffff;

    .iconfont {
      font-size: 16px;
      margin-right: 3px;
    }

    margin-bottom: 4px;
  }
}

//隐藏滚动条
::-webkit-scrollbar {
  display: none !important;
  scrollbar-width: none !important;
  -ms-overflow-style: none;
}

/deep/ .el-collapse {
  border: none !important;

  .el-collapse-item__header {
    padding: 0px 20px;
    height: 48px;
    background: #555555;
    border-radius: 0px 0px 0px 0px;
    opacity: 1;
    line-height: 48px;
    font-size: 16px;
    font-family: Microsoft YaHei-Regular;
    font-weight: 400;
    color: #ffffff;
    border: none;
    margin-top: 4px;
  }

  .el-collapse-item__content {
    padding: 0px;
  }

  .el-collapse-item__wrap {
    border: none;
  }
}

/* 试听 */
.audition {
  width: 48px;
  height: 20px;
  background: #ffedeb;
  border-radius: 2px 2px 2px 2px;
  font-size: 12px;
  font-family: Microsoft YaHei-Regular;
  font-weight: 400;
  color: #ff5e51;
  line-height: 20px;
  margin-left: 8px;
}

/* 节 */
.chat-item {
  width: 100%;
  border-bottom: 1px solid #555555;
  padding: 16px 20px;
  background: #383838;

  .active {
    color: #ff5e51;

    .iconfont,
    .title {
      color: #ff5e51 !important;
    }
  }

  .item-title {
    display: flex;
    width: 100%;
    align-items: center;
    cursor: pointer;
    width: calc(320px - 32px);
    justify-content: space-between;

    .iconfont {
      font-size: 14px;
      margin-right: 12px;
      color: #ffffff;
      flex-shrink: 0;
    }

    .title {
      max-width: calc(320px - 32px - 26px - 56px);
      font-size: 14px;
      font-family: Microsoft YaHei-Regular;
      font-weight: 400;
      color: #ffffff;
      line-height: 14px;
    }
  }

  .but-list {
    flex-shrink: 0;
    display: flex;
    justify-content: flex-end;
    margin-top: 24px;
  }
}

.keep-learn {
  margin-left: 12px;
  height: 24px;
  padding: 0px 12px;
  border-radius: 4px 4px 4px 4px;
  opacity: 1;
  border: 1px solid #ffffff;
  font-size: 12px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  line-height: 22px;
}

.bofangChater /deep/ .el-input__inner {
  background-color: #4b4b4b;
  border: 1px solid #4b4b4b;
  color: #ffffff;
  width: 220px;
}

.barrageInput {
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin-top: 278px; */
  width: 98%;
  margin-left: 1%;
  position: relative;

  .mt-10 {
    cursor: pointer;
    margin-right: 60px;
    position: absolute;
    right: 5px;
  }

  .sendBtn {
    position: absolute;
    right: 0;
  }
}

.barrageInput /deep/ .el-input-group__append {
  background-color: #4b4b4b;
  border: 1px solid #4b4b4b;
}

.white {
  font-size: 13px;
  padding: 5px;

  .wrap {
    .formNick {
      line-height: 21px;
    }

    .text {
      border-radius: 8px;
      background-color: #4b4b4b;
      padding: 12px;
      max-width: 90%;
      display: inline-block;
      word-wrap: break-word;
    }
  }
}

.information {
  height: 275px;
  overflow: auto;
  display: flex;
  flex-direction: column;
}

.dis-start {
  display: flex;
  flex-direction: column;
  align-items: start;
}

.dis-end {
  display: flex;
  flex-direction: column;
  align-items: end;
}

.lookBg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;

  .ifLook {
    width: 500px;
    background-color: #fff;
    padding: 10px;
    border-radius: 10px;
    border: 1px solid #ccc;

    .contents {
      width: 380px;
      margin: 20px auto;

      &>span {
        color: red;
      }
    }

    .button {
      width: 120px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 auto;
      border-radius: 10px;
      border: 1px solid #ccc;
      cursor: pointer;
    }
  }
}
</style>